import React, { useEffect } from 'react'
import Form from '../Form';
import PopularOutstations from '../PopularOutstations';
// import Coimbatore from '../../assets/places/Coimbatore.jpg'
import Bangalore from '../../assets/places/Bangalore.jpg'
import Chidambaramimg from '../../assets/places/Chidambaram.jpg'
import Tindivanam from '../../assets/places/tindivanam.jpg'
import Pondicherry from '../../assets/places/Pondicherry4.jpg'
import Vellore from '../../assets/places/Vellore.jpg'
import Trichy from '../../assets/places/Tiruchi.png'
import Vilupuram from '../../assets/places/Vilupuram.jpg'
import Thiruvanamalai from '../../assets/places/Tiruvannamalai.jpg'
import Cuddaloreimg from '../../assets/places/Tiruchi.png'
import chennai from '../../assets/places/Chennai.jpg'

import { Link } from "react-router-dom";

const Chidambaram = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <div className="form_bg relative bg-fixed flex justify-center px-3">
                <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
                    <Form />
                </div>
            </div>
            <div className=" grid grid-cols-1 mb-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Pondicherry} className="w-[309px] h-[309px]" alt="Chidambaram-to-Pondicherry " />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Pondicherry
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Cuddaloreimg} className="w-[309px] h-[309px]" alt="Chidambaram-to-Cuddalore " />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Cuddalore
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <img src={chennai} className="w-[309px] h-[309px]" alt="Chidambaram-to-Chennai" />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Chennai
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2  px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Tindivanam} className="w-[309px] h-[309px]" />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Tindivanam
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>

                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Vilupuram} className="w-[309px] h-[309px]" alt="Chidambaram-to-Vilupuram" />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Vilupuram
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Trichy} className="w-[309px] h-[309px]" />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Trichy
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2  px-4 text-align:center'>Book Now</a>
                </div>


                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Thiruvanamalai} className="w-[309px] h-[309px]" />
                    <h1 className="primary-color text-[23px] ">Chidambaram To Thiruvanamalai</h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>

                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Vellore} className="w-[309px] h-[309px]" alt="Chidambaram-to-vellore" />
                    <h1 className="primary-color text-[23px] ">
                        Chidambaram to Vellore
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>

            </div>
            < PopularOutstations />

        </div>
    )
}

export default Chidambaram
