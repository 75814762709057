import React, { useState } from "react";
import OneWay from "./OneWay";
import Roundtrip from "./Roundtrip";

const Form = () => {
  const [fromChange, setFormChange] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  // function changeFroms(e) {
  //   if (e.target.value == "oneway") {
  //     setFormChange(false);
  //   } else {
  //     setFormChange(true);
  //   }
  // }
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const onSubmit = (data) => {
  //   try {
  //     const response = axios.post(
  //       `https://api.telegram.org/bot${telegramApi}/sendMessage`,
  //       {
  //         chat_id: telegramChatId,
  //         text: "Customer Mobile number : +91 " + data.mob_number,
  //       }
  //     );
  //     if (response) {
  //       setMobileNumber(data.mob_number);
  //     }
  //   } catch (error) {
  //     alert("Please try again");
  //     console.error("Error sending Telegram message:", error);
  //     // Handle the error
  //   }
  // try {
  //   axios.post(
  //     `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
  //     {
  //       chat_id: netbixTelegramChatId,
  //       text: "Customer Mobile number : +91 " + data.mob_number,
  //     }
  //   );
  // } catch (error) {
  //   console.error("Error sending Telegram message:", error);
  //   // Handle the error
  // }
  // };
  // register("mob_number", {
  //   required: true,
  //   validate: (value) =>
  //     !isNaN(value) ? undefined : "Please enter a valid number",
  // });
  return (
    <div className="">
      
      <div className="container mx-auto ">
        <h3 className=" mt-4 font-bold text-[30px] sm:text-[40px] md:text-[50px] text-center">
          Book One Way Taxi{" "}
        </h3>
        <p className="sm:mt-2 md:mt-4 text-center text-sm ">
          {" "}
          We are providing one way taxi, outstation cabs, drop taxi and airport
          taxi service.
        </p>
        {/* {mobileNumber === "" && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="p-3">
                <div className=" relative">
                  <span
                    className={`top-1/2 left-0 h-full px-2 border text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${
                      errors.mob_number && "border border-red-500"
                    }`}
                  >
                    <span>
                      <img src={indiaFlag} className="w-6" />
                    </span>
                    <span className=" text-white">+91</span>
                  </span>
                  <input
                    type="text"
                    className={`w-full border  py-3 px-4 focus:outline-0 pl-20 ${
                      errors.mob_number && "border border-red-500"
                    }`}
                    {...register("mob_number", {
                      required: true,
                      validate: (value) => {
                        if (!isNaN(value)) {
                          return value.length === 10
                            ? undefined
                            : "Mobile number should be 10 digits";
                        } else {
                          return "Please enter a valid number";
                        }
                      },
                    })}
                    placeholder="Mobile number"
                    name="mob_number"
                  />
                </div>
                {errors.mob_number !== undefined && errors.mob_number.type === "validate" && <p className=" bg-red-700 text-white py-[1px] px-[5px] text-sm flex items-center rounded-sm mt-1">{errors.mob_number.message}</p>}
                
              </div>
            </div>
            <div className="text-center mt-3">
              <button className="mt-4py-3 px-6 th-btn">GET TAXI</button>
            </div>
          </form>
        )} */}
        <div className=" mt-3 px-3 border-black  mx-3">
          <div className="flex py-3 px-4 w-full gap-x-3 lg:gap-x-4 mx-auto justify-center gap-y-2 items-stretch  sm:flex-row">
            <span
              onClick={() => setFormChange(false)}
              className={` border-black  border py-2 px-3 rounded-3xl text-sm cursor-pointer min-w-[100px] text-center ${
                !fromChange ? "form_shadow" : ""
              }`}
            >
              One Way
            </span>
            <span
              onClick={() => setFormChange(true)}
              className={`border-black  border py-2 px-3 rounded-3xl text-sm cursor-pointer min-w-[100px] text-center ${
                fromChange ? "form_shadow" : ""
              }`}
            >
              Round Trip
            </span>
          </div>
        </div>
        {!fromChange ? <OneWay /> : <Roundtrip />}
      </div>
    </div>
  );
};

export default Form;
