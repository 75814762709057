import React from "react";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import bg from "../assets/images/servbg.png";

const PopularOutstations = () => {

  return (
    <div className="bg-secondary-color" >
      <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8">
        <div className=" text-center">
          <h3 className="   uppercaseCls text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
            Nama Shivayam Cabs OUTSTATION CAB SERVICES
          </h3>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              CHENNAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chennai to Vellore Drop Taxi
              </li>

            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              PONDICHERRY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Vellore Drop Taxi
              </li>

            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              CUDDALORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Cuddalore to Vellore Drop Taxi
              </li>
            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              CHIDAMBARAM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Chidambaram to Vellore Drop Taxi
              </li>
            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              Tindivanam DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tindivanam to Vellore Drop Taxi
              </li>
            </ul>
          </div>


          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              Vilupuram DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vilupuram to Vellore Drop Taxi
              </li>
            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              Trichy DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Tiruvannamalai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Vellore Drop Taxi
              </li>
            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              Tiruvannamalai DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Tiruvannamalai to Vellore Drop Taxi
              </li>
            </ul>
          </div>

          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              Vellore DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">

              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Chidambaram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Tindivanam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Vilupuram Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Tiruvannamalai Drop Taxi
              </li>
            </ul>
          </div>

          {/* <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              COIMBATORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Coimbatore to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
            Coimbatore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
    Coimbatore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Coimbatore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Coimbatore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Coimbatore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
      Coimbatore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Coimbatore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Coimbatore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Coimbatore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              BANGALORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Bangalore to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Bangalore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Bangalore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Bangalore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Bangalore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Bangalore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
      Bangalore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Bangalore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Bangalore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Bangalore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            SALEM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Salem to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Salem to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Salem to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Salem to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Salem to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            MADURAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Madurai to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Madurai to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
            Madurai to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Madurai to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Madurai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Madurai to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Madurai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Madurai to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Madurai to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Madurai to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            PONDICHERRY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
          Pondicherry to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Pondicherry to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
      Pondicherry to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
          Pondicherry to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
          Pondicherry to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
        Pondicherry to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Pondicherry to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
             Pondicherry to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
             Pondicherry to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            VELLORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Vellore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
            Vellore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
          Vellore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Vellore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Vellore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            TRICHY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Trichy to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
            Trichy to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Trichy to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Trichy to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="   uppercaseCls text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            KUMBAKONAM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
          Kumbakonam to ChennaNama Shivayam Cabs
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
              Kumbakonam to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#289db9]" />
                Kumbakonam to Neyveli Drop Taxi
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PopularOutstations;
