import React from 'react'


import { RiServiceFill } from "react-icons/ri";
import { CiSquareCheck } from "react-icons/ci";
import { GiPathDistance } from "react-icons/gi";
import bgslider from '../../assets/images/bg_slide.jpg';
import { Ri24HoursLine } from "react-icons/ri";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { PiTaxiFill } from "react-icons/pi";

const Chooseus = () => {
    const divStyle = {
        backgroundImage: `url(${bgslider})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    
      };
    return (
        <div class='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
            
            <div className='text-center font-bold text-3xl mt-3 mb-6'>Why Choose Us</div>
            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  gap-3 mt-6'>
                <div className='shadow-2xl  rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>24x7 Availability </div>
                  
                        <Ri24HoursLine   className='text-white' size={100} />
                        <span className='text-center'> We operate round the clock, ensuring you get where you need to go, no matter the time of day. </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>Pay Only for Your Ride</div>
                  
                    <HiOutlineCurrencyRupee   className='text-white' size={100} />
                        <span className='text-center'> Unlike traditional taxis, we don’t charge for the return trip. You only pay for your journey with us.  </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center' style={divStyle}>
                    <div className='text-white text-2xl'>Professional Drivers </div>
                  
                    <PiTaxiFill   className='text-white' size={100} />
                        <span className='text-center'> Our experienced drivers ensure a safe and comfortable ride, with a focus on punctuality and customer satisfaction.  </span>
                 
                </div>
                <div className='shadow-2xl bg-[black] rounded-lg flex flex-col text-white gap-3 justify-center items-center px-3 py-5 text-center ' style={divStyle}>
                    <div className='text-white text-2xl'>Wide Coverage</div>
                  
                    <GiPathDistance  className='text-white' size={100} />
                        <span className='text-center'>: We cover all major cities and towns across Tamil Nadu, Bangalore, and Pondicherry, making intercity travel simple and affordable.  </span>
                 
                </div>
            </div>

        </div>
    )
}

export default Chooseus