import React from "react";
import img1 from "../../assets/images/corporate_ser.jpg";
import img2 from "../../assets/images/city_rides.jpg";
import img3 from "../../assets/images/airport-rides.jpg";
import img4 from "../../assets/images/intercity-travel.jpg";
const OurServices = () => {
  return (
    <div id='ourservices'>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-3 mt-6'>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img3} className="rounded-lg h-[210px] w-full"/>
            </div>
            <h4 className="font-bold text-center text-3xl">Airport Transfers</h4>
            <p className="text-center text-lg">  Get to or from the airport comfortably with our reliable drop taxi service.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img2} className="rounded-lg h-[210px] w-full"/>
            </div>
            <h4 className="font-bold text-center text-3xl">One-Way Taxi Services</h4>
            <p className="text-center text-lg">  Need a drop-off at your destination without the hassle of booking a return trip? We’ve got you covered!</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img4} className="rounded-lg h-[210px] w-full"/>
            </div>
            <h4 className="font-bold text-center text-3xl">Long-Distance Travel</h4>
            <p className="text-center text-lg">  Whether it’s a business trip or a family vacation, we provide safe and affordable long-distance travel options.</p>
          </div>
      
        </div>
      </div>
      
    </div>
  );
};

export default OurServices;
