import React from "react";
import banner from "../assets/banner-gif.gif";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";

const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  return (
    <div className="form_bg relative bg-fixed flex justify-center px-3" style={divStyle}>
    <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
      <Form />
    </div>
  </div>
  );
};

export default BookingForm;
