const telegramApi = '7882871174:AAF2y3F2Esm4JC62AUrbVvc0Kd-6bW_dYOQ'; // Replace with your actual backend URL
const telegramChatId = 5942690641;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId = 1113684027;



// mobile number
const customerMobileNumber = 8903646183;
const front_url = "https://www.namashivayamcabs.in/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };